/* External dependencies */
import { Store, combineSlices, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { Epic, combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import { authnApiService } from './features/login/authn.api';
import * as loginEpics from './features/login/login.epic';
import { loginSlice } from './features/login/login.slice';

const rootReducer = combineSlices(loginSlice, authnApiService);

const rootEpic = combineEpics(...Object.values(loginEpics));

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware<unknown, unknown, RootState>();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(/* epicMiddleware, */ authnApiService.middleware),
    devTools: process.env.NODE_ENV === 'development',
  });

  setupListeners(store.dispatch);
  // epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppEpic<Input = unknown, Output extends Input = Input> = Epic<Input, Output, RootState>;
export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
