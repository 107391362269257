import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

import { COGNITO_USER_POOL_DATA, cognitoSignOut, getCognitoUserSession, getSessionUser } from '../../clients/cognito';
import {
  CustomAuthSignInInput,
  SendVerificationCodeInput,
  customAuthSignIn,
  sendVerificationCode,
} from '../../clients/custom-auth';
import { cleanUpSession, setupClient } from '../../clients/apollo';
import type { RootState } from '../../store';

const userPool = COGNITO_USER_POOL_DATA;

export interface MutationErrorResponse {
  error: { code: string };
}

export const authnApiService = createApi({
  reducerPath: 'authnApi',
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    initClient: builder.mutation({
      queryFn: async () => {
        try {
          // TODO: this request is not being fulfilled
          const session = await getCognitoUserSession();
          const currentUser = getSessionUser(session);
          const apolloClient = setupClient(session);

          // TODO: fix non-serializable object in action payload warning
          return { data: { apolloClient, currentUser } };
        } catch (error) {
          return { error } as MutationErrorResponse;
        }
      },
    }),

    requestVerificationCode: builder.mutation({
      queryFn: async (input: Omit<CustomAuthSignInInput, 'userPool'>) => {
        try {
          const currentSession = await customAuthSignIn({ ...input, userPool });

          return { data: { currentSession, userName: input.userName } };
        } catch (error) {
          return { error } as MutationErrorResponse;
        }
      },
    }),

    sendVerificationCode: builder.mutation({
      queryFn: async (input: Omit<SendVerificationCodeInput, 'userPool' | 'currentUserSession'>, api) => {
        try {
          const state = api.getState() as RootState;
          const currentUserSession = state.login.currentUserSession!;

          const cognitoUserSession = await sendVerificationCode({ ...input, userPool, currentUserSession });

          const currentUser = getSessionUser(cognitoUserSession);

          // TODO: only serializable data should be in action payload, we should store cognitoUserSession elsewhere if needed
          return { data: { /* cognitoUserSession, */ currentUser } };
        } catch (error) {
          return { error } as MutationErrorResponse;
        }
      },
    }),

    signOut: builder.mutation({
      queryFn: async () => {
        try {
          cognitoSignOut();
          cleanUpSession();

          return { data: {} };
        } catch (error) {
          return { error } as MutationErrorResponse;
        }
      },
    }),
  }),
});

export const {
  useInitClientMutation,
  useRequestVerificationCodeMutation,
  useSendVerificationCodeMutation,
  useSignOutMutation,
} = authnApiService;
