import React from 'react';
import { Provider } from 'react-redux';

import { setAnonymousClient } from './src/clients/apollo';
import { createStore } from './src/store';

const store = createStore();

export function wrapRootElement(props) {
  return <Provider store={store}>{props.element}</Provider>;
}

export const onClientEntry = async () => {
  setAnonymousClient();
};
