import { catchError, filter, of, switchMap } from 'rxjs';

import { COGNITO_USER_POOL_DATA, cognitoSignOut, getCognitoUserSession, getSessionUser } from '../../clients/cognito';
import { sendVerificationCode } from '../../clients/custom-auth';
import { setupClient } from '../../clients/apollo';
import { SessionUser } from '../../cognito.types';
import { getErrorMessage } from '../../shared/get-error-message';
import { AppEpic } from '../../store';
import { loginActions } from './login.slice';

const userPool = COGNITO_USER_POOL_DATA;

export const clientInitEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(loginActions.clientInitRequested.match),
    switchMap(async () => {
      const session = await getCognitoUserSession();
      const currentUser = getSessionUser(session) as SessionUser;

      setupClient(session);

      return loginActions.clientInitSucceeded({ currentUser });
    }),
    catchError((err) => {
      const error = getErrorMessage(err);

      return of(loginActions.clientInitFailed({ error }));
    }),
  );

export const logoutEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(loginActions.logoutRequested.match),
    switchMap(async () => {
      cognitoSignOut();

      return loginActions.logoutSucceeded();
    }),
    catchError((err) => {
      const error = getErrorMessage(err);

      return of(loginActions.logoutFailed({ error }));
    }),
  );

export const sendVerificationCodeEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(loginActions.verificationCodeRequested.match),
    switchMap(async (action$) => {
      const { currentUserSession, verificationCode } = state$.value.login;

      if (!currentUserSession || !verificationCode) {
        throw new Error('No user session or verification code');
      }

      const data = {
        userName: action$.payload.userName,
        currentUserSession,
        verificationCode,
        userPool,
      };

      return sendVerificationCode(data);
    }),
    catchError((err) => {
      const error = getErrorMessage(err);

      return of(loginActions.verificationCodeRequestRejected({ error }));
    }),
  );
