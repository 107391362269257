import * as AWSCognito from 'amazon-cognito-identity-js';

const asyncInitiateAuthUser = (
  cognitoUser: AWSCognito.CognitoUser,
  cognitoAuthenticationDetails: AWSCognito.AuthenticationDetails,
): Promise<AWSCognito.CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

    cognitoUser.initiateAuth(cognitoAuthenticationDetails, {
      onSuccess: (session) => resolve(session),
      onFailure: reject,
      customChallenge: resolve,
    });
  });
};

export interface CustomAuthSignInInput {
  userName: string;
  userPool: AWSCognito.ICognitoUserPoolData;
}

export async function customAuthSignIn(input: CustomAuthSignInInput): Promise<string> {
  const cognitoUserPool = new AWSCognito.CognitoUserPool(input.userPool);
  const userData = { Username: input.userName, Pool: cognitoUserPool };
  const cognitoUser = new AWSCognito.CognitoUser(userData);

  const cognitoAuthenticationDetails = new AWSCognito.AuthenticationDetails({
    Username: input.userName,
  });

  try {
    await asyncInitiateAuthUser(cognitoUser, cognitoAuthenticationDetails);

    return cognitoUser.Session;
  } catch (err) {
    throw err;
  }
}

const asyncSendCustomChallengeAnswer = (
  cognitoUser: AWSCognito.CognitoUser,
  verificationCode: string,
): Promise<AWSCognito.CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.sendCustomChallengeAnswer(verificationCode, {
      onSuccess: (session) => resolve(session),
      onFailure: reject,
    });
  });
};

export interface SendVerificationCodeInput {
  userName: string;
  currentUserSession: string;
  verificationCode: string;
  userPool: AWSCognito.ICognitoUserPoolData;
}

export async function sendVerificationCode(input: SendVerificationCodeInput) {
  const cognitoUserPool = new AWSCognito.CognitoUserPool(input.userPool);
  const userData = { Username: input.userName, Pool: cognitoUserPool };
  const cognitoUser = new AWSCognito.CognitoUser(userData);

  cognitoUser.Session = input.currentUserSession;

  const session = await asyncSendCustomChallengeAnswer(cognitoUser, input.verificationCode);

  return session;
}
